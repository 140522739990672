body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

select.form-select {
    height: 2.3rem !important;
}

input.form-control[type='text'] {
    height: 2.3rem !important;
}

.cropper-view-box,
.cropper-face {
    border-radius: 100%;
}

.list-group-item {
    border: none !important;
}