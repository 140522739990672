html {
    box-sizing: border-box;
    color: #222;
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
}

*,
*::before,
*::after {
    box-sizing: inherit;
    color: inherit;
}

body {
    color: #333;
    background: #f5f5f5;
    overflow-y: hidden;
}

#root {
    height: 100%;
}

.app {
    display: flex;
    min-width: 800px;
}

.container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 15px;
}

.aside {
    width: 250px;
}

.dashboard {
    display: grid;
    grid-template-columns: 250px auto;
}

.nav-menu {
    display: flex;
    padding: 0.6em;
}
.nav-menu__item {
    padding: 0.6em;
    text-transform: uppercase;
    text-decoration: none;
}
.nav-menu__item.active {
    color: #fff;
    background-color: #2297d1;
}

.main-container {
    background-color: #fbfcfd;
    min-height: 90vh;
    max-height: 90vh;
    overflow-y: scroll;
}

.accordion.mb0 > .card > .card-header {
    margin-bottom: 0;
}

.loading {
    opacity: 0.5;
    background-color: #dfdfdf;
}

input[type='file'] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.leaflet-container {
    height: 400px;
}

.bg-light-blue {
    background-color: #bbeffd !important;
}

.leaflet-container {
    cursor: crosshair;
}

.login-form {
    width: 400px;
    margin: 100px auto;
    background: linear-gradient(
        to bottom,
        rgba(245, 245, 245, 1) 0%,
        rgba(255, 255, 255, 1) 19%,
        rgba(255, 255, 255, 1) 77%,
        rgba(245, 245, 245, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#f5f5f5',GradientType=0 );
    color: #000;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.portal_popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 30px;
    box-sizing: border-box;

    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
}

.portal_popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

.portal_popup .main_popup {
    background-color: #f8f8f8;
    width: 440px;
    max-height: 700px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);

    padding: 20px;

    z-index: 1;
}

.nav-item {
    cursor: pointer;
}
