.card {
    padding: 2em;
}

.read-the-docs {
    color: #888;
}

.wrapperBuyer {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.containerDnd {
    flex: 1;
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    align-items: flex-start;
    gap: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: rgb(67, 182, 221);
    padding: 20px;

    &::-webkit-scrollbar {
        height: 9px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(255, 255, 255);
    }

    &::-webkit-scrollbar-track {
        background-color: rgb(147, 219, 243);
    }
}

.board {
    position: relative;
    min-width: 280px;
    padding: 0 0 20px 10px;
    min-height: 200px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
    background-color: rgb(223, 223, 223);
    border-radius: 5px;
}

.dropZone {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: hidden;
    justify-content: space-between;
    gap: 10px;
    margin-right: 5px;
}

.scroll {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;

    &::-webkit-scrollbar {
        width: 9px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(255, 255, 255);
    }

    &::-webkit-scrollbar-track {
        background-color: rgb(147, 219, 243);
    }
}

.cardDate {
    width: 248px;
    display: flex;
    padding: 4px;
    gap: 9px;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    background-color: #ecedee;
    color: rgb(15, 15, 50);
    border-radius: 5px;
    font-size: 13px;
}

.warmCard {
    border: 3px solid orange;
}

.hotCard {
    border: 3px solid red;
}

.coldCard {
    border: 3px solid blue;
}

.warmList {
    background-color: rgb(248, 191, 85) !important;
}

.hotList {
    background-color: rgb(252, 78, 78) !important;
}

.coldList {
    background-color: rgb(159, 159, 255) !important;
}

.nameByer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.cardDate:hover {
    cursor: pointer;
    box-shadow: 1px 2px 10px rgb(90, 90, 90);
}

.expired {
    color: rgb(202, 85, 85);
}

.realty {
    display: flex;
    flex-wrap: wrap;
}

.fioAgent {
    font-size: 11px;
}

.headerBoard {
    background-color: rgb(223, 223, 223);
    padding: 8px 8px 8px 0;
    width: calc(100%);
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
}

.boardLabel {
    display: flex;
    flex-direction: column;
    color: rgb(15, 15, 50);
    font-weight: 700;
    font-size: 14px;
}

.boardLabelDate {
    color: rgb(15, 15, 50);
    font-weight: 400;
    font-size: 14px;
}

.boardLink {
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
}

.cardHeaderButton {
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerDnd_empty {
    height: 15px;
    background-color: rgb(67, 182, 221);
}
