body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

body,
html,
.app {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    width: 100%;
}

.nav {
    display: flex;
    flex-wrap: nowrap;
    padding: 5px 10px;
}

.navbar-inner {
    margin-bottom: 0px;
    min-height: 40px;
    padding-right: 20px;
    padding-left: 20px;
    background: #080808;
    filter: none;
    text-shadow: 1px 1px 1px #0a0c0e;
    border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
    color: #fff;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    zoom: 1;
    border-bottom: 1px solid #38383d;
}

.navvbar {
    line-height: 20px;
}

.navvbar .brand {
    display: block;
    float: left;
    padding: 10px 20px 10px;
    margin-left: -20px;
    font-size: 20px;
    font-weight: 200;
    color: #777;
    text-shadow: 0 1px 0 #fff;
    font-size: 35px;
}
.navvbar .brand span {
    color: #fff;
    text-shadow: none;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    vertical-align: inherit !important;
}

table td span.mobile {
    background: #eee;
    color: dimgrey;
    display: none;
    font-size: 10px;
    font-weight: bold;
    padding: 5px;
    position: absolute;
    text-transform: uppercase;
    top: 0;
    right: 0;
}

.menu-bar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.hidden-menu-ticker {
    display: none;
}

.hidden-menu {
    overflow-y: hidden;
}
.hidden-menu ul {
    position: relative;
    list-style: none;
    font-size: 14px;
    margin: 0;
    overflow-y: scroll;
    height: 100%;
}

.hidden-menu ul::-webkit-scrollbar {
    display: none;
}

.hidden-menu ul li {
    position: relative;
    list-style: none;
    width: 100%;
    line-height: 20px;
    font-size: 16px;
}

.hidden-menu ul li > a,
.hidden-menu ul li > span {
    display: flex;
    width: 100%;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    align-items: center;
    padding: 15px 10px 14px 10px;
    color: #fff;
    border-top: none;
    border-bottom: 1px solid transparent;
    border-left: none;
    border-right: none;
    text-decoration: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    cursor: pointer;
}
.hidden-menu ul li.active a {
    background: #578ebe;
    border-bottom: 1px solid #578ebe;
}
.hidden-menu ul li a:hover {
    color: #fff;
    background-color: #080808;
}

.hidden-menu ul li a i,
.hidden-menu ul li a svg.svg-inline--fa,
.hidden-menu ul li span svg.svg-inline--fa,
.hidden-menu ul li button i,
.hidden-menu ul li button svg.svg-inline--fa {
    margin-right: 0;
    min-width: 25px;
    text-align: center;
    /* transition: all .1s linear .23s; */
}
.menu-bar.show .hidden-menu ul li a i,
.menu-bar.show .hidden-menu ul li a svg.svg-inline--fa,
.menu-bar.show .hidden-menu ul li span i,
.menu-bar.show .hidden-menu ul li span svg.svg-inline--fa,
.menu-bar.show .hidden-menu ul li button i,
.menu-bar.show .hidden-menu ul li button svg.svg-inline--fa {
    margin-right: 10px;
}

.btn-menu {
    color: #fff;
    padding: 5px;
    cursor: pointer;
    transition: left 0.23s;
    z-index: 3;
    width: 35px;
    transform: translateZ(0);
    backface-visibility: hidden;
    align-self: center;
    margin: 0.3rem;
}
.btn-menu span {
    display: block;
    height: 4px;
    background-color: #fff;
    margin: 4px 0 0;
    transition: all linear 0.23s;
    position: relative;
}
.btn-menu span.first {
    margin-top: 0;
}

.menu-bar .hidden-menu ul li a:hover i,
.menu-bar .hidden-menu ul li a:hover svg {
    transform: scale(1.5);
}

.menu-bar.show .btn-menu {
    align-self: flex-end;
}
.menu-bar.show .hidden-menu {
    width: 200px;
}
.menu-bar.show .btn-menu span.first {
    transform: rotate(45deg);
    top: 8px;
}
.menu-bar.show .btn-menu span.second {
    opacity: 0;
}
.menu-bar.show .btn-menu span.third {
    transform: rotate(-45deg);
    top: -8px;
}
.menu-bar .hidden-menu small {
    display: none;
}
.menu-bar.show .hidden-menu small {
    display: block;
}
.top-nav {
    z-index: 1000;
    height: 3rem;
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.15),
        0 0 2px rgba(0, 0, 0, 0.1) !important;
}
.left-side-nav {
    width: 100%;
    height: 100%;
    color: white;
    background-color: #1c2b36;
}

.navigation-link {
    align-items: center;
    color: #0e0e10;
    display: flex;
    text-align: center;
    white-space: nowrap;
}

/* ::-webkit-scrollbar {
	background-color: rgba(255, 255, 255, 0.414);
}
 
::-webkit-scrollbar-track {

	-webkit-border-radius: 10px;
	border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #9595a1;q
	box-shadow: 0 0 1px 1px hsla(0,0%,100%,.25);
} */

.photo-gallery {
    display: flex;
    flex-wrap: wrap;
}

.photo-thumbs {
    padding: 5px;
}

.slider-container {
    height: 500px;
}
.slider-container .carousel-root {
    height: 100%;
}

.slider-container .carousel.carousel-slider {
    height: 100%;
}

._loading_overlay_content {
    top: 45%;
    left: 50%;
    position: fixed;
}

.fixed-bottom {
    position: fixed !important;
    bottom: 5px;
    font-size: 14px;
}

.comment-dot {
    height: 7px;
    width: 7px;
    margin-top: 3px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

@media (max-width: 800px) {
    table thead {
        left: -9999px;
        position: absolute;
        visibility: hidden;
    }
    table tr {
        border-bottom: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }
    table td {
        border: 1px solid;
        margin: 0 -1px -1px 0;
        padding: 1rem !important;
        position: relative;
        width: 50%;
    }
    /* Отображение span заголовков */
    table td span {
        display: block;
    }

    .hidden-menu-ticker:checked ~ .btn-menu {
        left: auto;
        right: 5px;
    }
    .hidden-menu-ticker:checked ~ .hidden-menu {
        width: 100%;
    }
}

@media (max-width: 357px) {
    table thead {
        left: -9999px;
        position: absolute;
        visibility: hidden;
    }
    table tr {
        border-bottom: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
    }
    table td {
        border: 1px solid;
        margin: 0 -1px -1px 0;
        padding: 1rem !important;
        position: relative;
        width: 100%;
    }
    /* Отображение span заголовков */
    table td span {
        display: block;
    }
}
