.ace-absolute {
    position: absolute !important;
}
.ace-relative {
    position: relative !important;
}
.ace-bottom-0 {
    bottom: 0 !important;
}
.ace-flex {
    display: flex !important;
}
.ace-flex-column {
    flex-direction: column !important;
}
.ace-flex-nowrap {
    flex-wrap: nowrap !important;
}
.ace-flex-grow-1 {
    flex-grow: 1 !important;
}
.ace-align-items-stretch {
    align-items: stretch !important;
}
.ace-align-self-center {
    align-self: center !important;
}
.ace-align-items-center {
    align-items: center !important;
}
.ace-justify-content-start {
    justify-content: flex-start !important;
}
.ace-justify-content-center {
    justify-content: center !important;
}
.ace-elevation-1 {
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.15),
        0 0 2px rgba(0, 0, 0, 0.1) !important;
}
.ace-z-default {
    z-index: 1 !important;
}

.ace-left-0 {
    left: 0 !important;
}
.ace-overflow-auto {
    overflow: auto !important;
}
.ace-overflow-hidden {
    overflow: hidden !important;
}
.ace-right-0 {
    right: 0 !important;
}
.ace-top-0 {
    top: 0 !important;
}
.ace-full-height {
    height: 100% !important;
}
.ace-full-width {
    width: 100% !important;
}
.ace-flex-shrink-0 {
    flex-shrink: 0 !important;
}
